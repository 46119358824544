import classNames from 'classnames';
import { graphql } from 'gatsby';
import Cookies from 'js-cookie';
import React, { Component } from 'react';
import IntroScreen from '../components/intro-screen';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import { isValidJson } from '../helpers';
import sectionConductor from '../sections/section-conductor';
import SectionHomeCarousel from '../sections/section-home-carousel';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skipIntro: !(
        typeof Cookies.get('skipIntro') === 'undefined' || Cookies.get('skipIntro') === 'false'
      ),
      transitionEnded: false,
      introCompleted: false,
    };

    this.onDoneAnimating = this.onDoneAnimating.bind(this);
  }

  introCompleted() {
    this.setState({
      introCompleted: true,
    });
  }

  onDoneAnimating() {
    this.setState({
      transitionEnded: true,
      introCompleted: true,
    });
  }

  render() {
    const { data, location, pageContext } = this.props;

    const { contentfulLayout: page } = data;

    const indexStructuredData = [];
    if (page.structuredData) {
      for (let i = 0; i < page.structuredData.length; i++) {
        if (isValidJson(page.structuredData[i].json.json)) {
          indexStructuredData.push(JSON.parse(page.structuredData[i].json.json));
        }
      }
    }

    const homepageCarousel = page.sections.filter(
      (section) => section.__typename === 'ContentfulSectionHomeCarousel'
    );

    // Grab rest of sections that are not homepage carousel
    const restOfSections = page.sections.filter(
      (section) => section.__typename !== 'ContentfulSectionHomeCarousel'
    );

    // console.log('rest of Sections', restOfSections);

    const renderedSections = restOfSections.map((section, i) => {
      // Intercept to add extra props
      if (section.__typename === 'ContentfulSectionHomeCarousel') {
        return (
          <SectionHomeCarousel
            key={i}
            {...section}
            onDoneAnimating={this.onDoneAnimating}
            introCompleted={this.state.introCompleted}
            skipIntro={this.state.skipIntro}
          />
        );
      }
      return sectionConductor(section, i);
    });

    const pageWrapClasses = classNames({
      page: true,
      [`page--${page.contentfulid}`]: true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          <SEO
            title={page.title}
            structuredData={indexStructuredData.length > 0 ? indexStructuredData : null}
            metadata={page.metadata}
            routeName="index"
          />

          {homepageCarousel.length > 0 &&
            homepageCarousel.map((carousel, i) => (
              <SectionHomeCarousel
                key={i}
                {...carousel}
                onDoneAnimating={this.onDoneAnimating}
                introCompleted={this.state.introCompleted}
                skipIntro={this.state.skipIntro}
              />
            ))}
          {<div id="scrollToFirstSection">{renderedSections}</div>}
        </Layout>
        {this.state.skipIntro === 'true' || this.state.skipIntro ? (
          <IntroScreen hide onDoneAnimating={this.onDoneAnimating} />
        ) : (
          <IntroScreen hide={false} onDoneAnimating={this.onDoneAnimating} />
        )}
      </div>
    );
  }
}

export default HomePage;

export const pageQuery = graphql`
  query ($contentfulid: String!, $whatRegion: String!) {
    contentfulLayout(contentfulid: { eq: $contentfulid }, whatRegion: { eq: $whatRegion }) {
      contentfulid
      title
      url
      structuredData {
        json {
          json
        }
      }
      metadata {
        ...Metadata
      }
      ...AllSectionsFragment
    }
  }
`;
