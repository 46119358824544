import classNames from 'classnames';
import Cookies from 'js-cookie';
import React from 'react';

class IntroScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAnimating: false,
      step: 1,
    };

    this.updateTransition = this.updateTransition.bind(this);
  }

  componentDidMount() {
    if (this.props.hide) {
      // console.log('this.props.hide is true. component mounted and do nothing');
    }

    if (!this.props.hide) {
      // console.log(
      //   'IntroScreen - componentDidMount() -> adding body fixed. setState isAnimating: true'
      // );
      document.body.classList.add('fixed');
      this.setState({
        isAnimating: true,
      });
    }
  }

  updateTransition() {
    // console.log('update Transition step');
    if (this.state.step < 4) {
      this.setState(
        {
          step: this.state.step + 1,
        }
        // () => console.log('updated state step!')
      );
    } else {
      // console.log('endTransition() is fired. setting Cookie showIntro to false. removing body fixed class');
      Cookies.set('skipIntro', 'true');
      document.body.classList.remove('fixed');

      // Trigger Carousel
      this.props.onDoneAnimating();
    }
  }

  render() {
    if (this.props.hide) {
      return null;
    }
    return (
      <div
        className={`intro-screen ${this.state.isAnimating ? 'intro-screen--animate' : ''} ${
          this.props.hide ? 'intro-screen--hide' : ''
        }`}
        onTransitionEnd={() => this.updateTransition()}
      >
        <div className="intro-screen__steps">
          <div className="intro-screen__step">Rethink</div>
          <div className="intro-screen__step">your</div>
          <div className="intro-screen__step">thinking</div>
        </div>
      </div>
    );
  }
}

export default IntroScreen;
